import { KcApp, defaultKcProps, getKcContext } from "keycloakify";


// For production uncomment this line
const { kcContext } = getKcContext();

// For production comment next line
// const { kcContext } = getKcContext({"mockPageId": "login.ftl"});

if( kcContext === undefined ){
    throw new Error(
        "This app is a Keycloak theme" +
        "It isn't meant to be deployed outside of Keycloak"
    );
}

const LogIn = () => {
  return (
    <div className="bg-white h-screen">
      <div className="flex justify-center h-screen">
        <div className="bg-cover bg-center lg:block lg:w-2/3 bg-image"></div>
        <div className="flex items-center w-full max-w-xl mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-center text-gray-700">INCIT-EV platform</h2>
              <p className="mt-3 text-gray-500">Innovative EV technologies testing in Amsterdam, Paris, Tallinn, Torino and Zaragoza</p>
            </div>
            <div className="mt-6">
              <KcApp
                kcContext={kcContext}
                {...{
                    ...defaultKcProps,
                    kcHeaderWrapperClass: "hidden",
                    // kcLoginClass: "text-red-400",
                    // kcFormCardClass: "text-red-400",
                    // kcInputClass: "rounded-full",
                    kcButtonPrimaryClass: "rounded-full bg-primary-400 hover:bg-primary-500 text-white hover:text-white",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogIn
